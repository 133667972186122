// SkiItem.js
import React, { useState } from 'react';
import { MdDelete, MdArrowDropDown, MdArrowDropUp, MdRestore, MdEdit } from "react-icons/md"; // Import MdNewReleases
import SkiDetail from '../SkiDetail/SkiDetail';
import ConfirmationModal from '../../../components/common/ConfirmationsModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';

const SkiItem = ({
  ski,
  handleCheckboxChange,
  handleEdit,
  handleArchive, // Add this handler
  handleDelete, // Existing delete handler
  handleUnarchive,
  selectedSkis,
  expandedSkiId,
  toggleDetails
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const showDetails = ski.id === expandedSkiId;

  // Helper function to determine if the ski is new (added within the last year)
  const isNew = () => {
    if (!ski.dateAdded) return false; // If dateAdded is not available, return false
    const twoMonthAgo = new Date();
    twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);
    const dateAdded = new Date(ski.dateAdded);
    return dateAdded >= twoMonthAgo;
  };

  const handleDeleteClick = () => setShowModal(true);

  const handleArchiveClick = async () => {
    await handleArchive(ski.id);
    setShowModal(false);
  };

  const handleDeleteFinalClick = async () => {
    await handleDelete(ski.id); // Perform final deletion
    setShowModal(false);
  };

  const handleUnarchiveClick = async () => {
    await handleUnarchive(ski.id); // Unarchive the ski
  };

  return (
    <div className={`${showDetails ? 'bg-container border border-sbtn shadow py-2 rounded-xl' : ''} ${selectedSkis[ski.id] ? 'bg-selected rounded-xl' : ''} px-2 animate-fade-down animate-duration-300`}>
      {/* Main ski item row */}
      <div
        className={`flex items-center cursor-pointer px-2 hover:font-semibold`}
        onClick={() => toggleDetails(ski.id)}
      >
        <div className={`flex items-center space-x-1 ${showDetails ? 'font-semibold' : ''}`}>

          <input
            type="checkbox"
            checked={selectedSkis[ski.id] || false}
            onChange={() => handleCheckboxChange(ski.id)}
            className="w-4 h-4 mr-1"
            onClick={(e) => e.stopPropagation()}
          />
          <span>{ski.serialNumber}</span>
          <span>/</span>
          <span>{ski.grind}</span>
          <span>/</span>
          <span>{t(ski.style)}</span>



          <p className='mx-1'>{showDetails ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />}</p>

          {isNew() && (
            <p className='text-btn text-xs'>{t('new')}</p>
          )}
        </div>

        <div className='flex items-center flex-1 justify-end space-x-2'>

          <div
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(ski);
            }}
            className='hidden md:block hover:underline font-semibold rounded-full p-2 cursor-pointer'
          >
            {t('edit')}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(ski);
            }}
            className='md:hidden block bg-btn text-btntxt hover:opacity-90 rounded-full p-2 cursor-pointer'>
            <MdEdit />
          </div>
          {ski.archived ? (
            // If ski is archived, show restore icon
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleUnarchiveClick(); // Unarchive the ski without opening modal
              }}
              className='bg-gradient-to-b from-green-500 to-green-700 hover:opacity-90 rounded-full p-2 cursor-pointer'
            >
              <MdRestore className="text-white" />
            </div>
          ) : (
            // If ski is not archived, show delete icon
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(); // Show modal on delete click
              }}
              className='bg-delete hover:opacity-90 rounded-full p-2 cursor-pointer'
            >
              <MdDelete className="text-white" />
            </div>
          )}
        </div>
      </div>

      {/* Modal for Archive/Delete confirmation */}
      {showModal && !ski.archived && (
        <ConfirmationModal
          onClose={() => setShowModal(false)}
          onArchive={handleArchiveClick}
          onDelete={handleDeleteFinalClick}
          title={t('confirm_archive')}
          message={t('confirm_archive')}
          confirmText={t('yes')}
          cancelText={t('no')}
        />
      )}

      {/* Ski details */}
      {showDetails && (
        <div className='mt-2'>
          <SkiDetail ski={ski} />
        </div>
      )}
    </div>
  );
};

export default SkiItem;
