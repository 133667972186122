import React from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useProfileActions } from '../../hooks/useProfileActions';
import Spinner from '../../components/common/Spinner';
import ProfileImage from '../../components/profileImage/ProfileImage';




const AccountPage = () => {
  const { user, checkingStatus } = useAuth();
  const { isChangingImg, errorMessage, updateProfileImage } = useProfileActions(user);

  const { t } = useTranslation();

  if (checkingStatus) {
    return <Spinner />;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Norwegian date format: day.month.year
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    return date.toLocaleDateString('nb-NO', options);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>mySkiPark: Account</title>
      </Helmet>
      <div className="flex flex-col md:flex-row md:items-center md:justify-center mx-auto mt-10 md:mt-32 px-4 animate-fade-down animate-duration-300 md:space-x-10 ">
        <ProfileImage
          isChangingImg={isChangingImg}
          photoURL={user?.photoURL}
          handleImageChange={updateProfileImage}
        />

        <div className='flex flex-col text-center'>
          <p>{t('hello')}</p>
          <h1 className="font-bold text-2xl">{user?.displayName || 'there'}</h1>
          <div className='my-4'>
            <p className="text-sm">{user?.email || ''}</p>
            <p className="text-sm">{t('joined')}: {user?.metadata.creationTime ? formatDate(user.metadata.creationTime) : ''}</p>
            {errorMessage && <div className="error-message text-red-500">{errorMessage}</div>}
          </div>

          <Link className='flex self-center py-3 px-5 bg-btn w-fit rounded-3xl text-btntxt text-center hover:opacity-90' to={'/settings'}>{t('settings')}</Link>


        </div>

      </div>
    </HelmetProvider>
  );
};

export default AccountPage;
