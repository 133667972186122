import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import useTournamentResults from '../../hooks/useTournamentResults';
import Spinner from '../../components/common/Spinner';
import DateSort from './DateSort/DateSort';
import { MdDelete } from "react-icons/md";
import { getSkiData } from '../../firebase/firestoreFunctions';
import { useTranslation } from 'react-i18next';
import ResultDetails from './ResultDetails/ResultDetails';
import ResultsSearch from './ResultSearch/ResultSearch';

const ResultsPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [resultsToShow, setResultsToShow] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const { results, loading, error, deleteResult } = useTournamentResults();
    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [skiDetails, setSkiDetails] = useState({});
    const [showDetails, setShowDetails] = useState({});

    useEffect(() => {
        const fetchSkiDetails = async (userId, skiId) => {
            try {
                const data = await getSkiData(userId, skiId);
                setSkiDetails(prevDetails => ({
                    ...prevDetails,
                    [skiId]: data
                }));
            } catch (error) {
                console.error("Error fetching ski details:", error);
            }
        };

        results.forEach(result => {
            result.rankings.forEach(ranking => {
                fetchSkiDetails(user.uid, ranking.skiId);
            });
        });
    }, [results, user.uid]);

    useEffect(() => {
        const filteredSortedResults = results
            .filter(result =>
                result.style.toLowerCase().includes(searchTerm.toLowerCase()) ||
                result.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                result.rankings.some(ranking =>
                    skiDetails[ranking.skiId]?.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    skiDetails[ranking.skiId]?.grind?.toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
            .sort((a, b) => {
                const dateA = new Date(a.timestamp); // Directly convert the ISO string or Date to a Date object
                const dateB = new Date(b.timestamp); // Directly convert the ISO string or Date to a Date object
                return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            });

        setResultsToShow(filteredSortedResults);
    }, [results, searchTerm, sortOrder, skiDetails]);



    const toggleDetails = (resultId) => {
        setShowDetails(prevDetails => ({
            ...prevDetails,
            [resultId]: !prevDetails[resultId]
        }));
    };

    const handleEdit = (resultId) => {
        const resultToEdit = results.find(result => result.id === resultId);
        if (!resultToEdit) return;
        const resultDataWithDetails = {
            ...resultToEdit,
            rankings: resultToEdit.rankings.map(ranking => ({
                ...ranking,
                grind: skiDetails[ranking.skiId]?.grind || '', // Retrieve grind from skiDetails or set default value
                serialNumber: skiDetails[ranking.skiId]?.serialNumber || '' // Retrieve serialNumber from skiDetails or set default value
            }))
        };
        navigate(`/edit-result/${resultId}`, { state: { resultId, resultData: resultDataWithDetails } });
    };


    const handleDelete = async (resultId) => {
        if (window.confirm("Are you sure you want to delete this result?")) {
            try {
                await deleteResult(user.uid, resultId);
                const updatedResults = resultsToShow.filter(result => result.id !== resultId);
                setResultsToShow(updatedResults);
            } catch (error) {
                console.error("Error deleting tournament result:", error);
            }
        }
    };

    const highlightSearchTerm = (text) => {
        if (!searchTerm.trim()) return text;
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? <mark key={index}>{part}</mark> : part
        );
    };


    if (loading) return <Spinner />;
    if (error) return <div>Error loading results: {error.message}</div>;

    return (
        <HelmetProvider>
            <Helmet>
                <title>mySkiPark: Results</title>
            </Helmet>
            <div className='py-4 px-2'>
                <div className='flex items-center justify-between space-x-4'>
                    <ResultsSearch onSearchChange={setSearchTerm} />
                    <DateSort onSortChange={setSortOrder} />
                </div>
                {resultsToShow.length > 0 ? (
                    resultsToShow.map(result => (
                        <div key={result.id} className='bg-container shadow rounded-xl my-5 p-5 animate-fade-down animate-duration-300'>
                            <div className='flex justify-between items-start'>
                                <div>
                                    <h3 className='font-semibold text-lg'>
                                        {highlightSearchTerm(t(result.style))} / {highlightSearchTerm(`${result.temperature}°C`)}
                                    </h3>
                                    <i className='text-sm'>{highlightSearchTerm(result.location)}</i>
                                </div>

                                <div className='flex items-center  space-x-2'>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(result.id)
                                    }} className='hover:underline font-semibold rounded-full p-2 cursor-pointer'>
                                        {t('edit')}
                                    </div>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(result.id);
                                    }} className='bg-delete rounded-full p-2 cursor-pointer'>
                                        <MdDelete className="text-white" />
                                    </div>
                                </div>
                            </div>
                            <ul className='my-2 p-2 space-y-2'>
                                {result.rankings.map((ranking, index) => (
                                    <li key={index} className='flex py-1'>
                                        <span className='w-1/3'>{highlightSearchTerm(skiDetails[ranking.skiId] ? skiDetails[ranking.skiId].serialNumber : 'Deleted')}</span>
                                        <span className='w-1/3 text-center'>{highlightSearchTerm(skiDetails[ranking.skiId] ? skiDetails[ranking.skiId].grind : '')}</span>
                                        <span className='w-1/3 text-end'>{ranking.score}</span>
                                    </li>
                                ))}
                            </ul>
                            <ResultDetails result={result} showDetails={showDetails[result.id]} />
                            <div className='flex justify-between mt-2'>
                                <button className='py-2 px-4 rounded-3xl bg-btn text-btntxt text-sm hover:opacity-90' onClick={() => toggleDetails(result.id)}>{showDetails[result.id] ? t('hide') : t('details')} </button>
                                <div className='flex items-center'>
                                    <span className='px-2 border-r'>
                                        {/* Convert timestamp to Date object if needed */}
                                        {new Date(result.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </span>
                                    <span className='px-2'>
                                        {/* Convert timestamp to Date object if needed */}
                                        {new Date(result.timestamp).toLocaleDateString()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='my-4'>
                        <div className='italic'>{t('no_test_results_available')}.</div>
                    </div>
                )}
            </div>
        </HelmetProvider>
    );
};

export default ResultsPage;
