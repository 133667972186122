import { useState, useEffect } from 'react';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../context/AuthContext';

export const useSkis = () => {
    const [skis, setSkis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        if (!user) return;
        setLoading(true);
        const skiCollectionRef = collection(db, `users/${user.uid}/skis`);
        const q = query(skiCollectionRef, orderBy("serialNumber"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const fetchedSkis = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSkis(fetchedSkis);
            setLoading(false);
        }, (err) => {
            setError(err);
            setLoading(false);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [user]);

    const addSki = async (skiData) => {
        if (!user) return;
        try {
            await addDoc(collection(db, `users/${user.uid}/skis`), skiData);
            // No need to manually update `skis` as onSnapshot handles it
        } catch (error) {
            setError(error);
        }
    };

    const updateSki = async (skiId, updatedSkiData) => {
        if (!user) return;
        try {
            const skiDocRef = doc(db, `users/${user.uid}/skis`, skiId);
            await updateDoc(skiDocRef, updatedSkiData);
            // No need to manually update `skis` as onSnapshot handles it
        } catch (error) {
            setError(error);
        }
    };

    const deleteSki = async (skiId) => {
        if (!user) return;
        try {
            const skiDocRef = doc(db, `users/${user.uid}/skis`, skiId);
            await deleteDoc(skiDocRef);
            // No need to manually update `skis` as onSnapshot handles it
        } catch (error) {
            setError(error);
        }
    };

    const updateSkisList = (newSkis) => {
        // This function might be redundant with onSnapshot, consider removing if not needed
        setSkis(newSkis);
    };

    return { skis, loading, error, addSki, updateSki, deleteSki, updateSkisList };
};
