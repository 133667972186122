// EditResultPage.js
import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateTournamentResult } from '../../../firebase/firestoreFunctions';
import { useAuth } from '../../../context/AuthContext';
import SaveTestInput from '../../TestingPage/TestResultPage/SaveTestInput/SaveTestInput';
import { useTranslation } from 'react-i18next';
import BackBtn from '../../../components/common/BackBtn/BackBtn';
import { formatDateForInputWithTime } from '../../../helpers/helpers';

const EditResultPage = () => {
    const { t } = useTranslation();
    const { resultId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();

    const [resultData, setResultData] = useState(location.state?.resultData || {
        location: '',
        style: '',
        temperature: '',
        snowTemperature: '',
        humidity: '',
        snowType: '',
        comment: '',
        timestamp: new Date().toISOString(),  // Default to current time
        rankings: []
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("score-")) {
            const index = parseInt(name.split("-")[1]);
            const updatedRankings = [...resultData.rankings];
            const updatedRanking = { ...updatedRankings[index], score: Number(value) };
            updatedRankings[index] = updatedRanking;
            setResultData({ ...resultData, rankings: updatedRankings });
        } else if (name === 'date') {
            const newDate = new Date(value);
            if (!isNaN(newDate.getTime())) {  // Check if newDate is a valid date
                setResultData(prevData => ({
                    ...prevData,
                    timestamp: newDate.toISOString()
                }));
            } else {
                console.error('Invalid date provided:', value);
            }
        } else {
            setResultData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let timestampToUse;

            // Check if the timestamp is a Firestore Timestamp object
            if (resultData.timestamp && typeof resultData.timestamp === 'object' && 'seconds' in resultData.timestamp && 'nanoseconds' in resultData.timestamp) {
                // Convert Firestore Timestamp to JavaScript Date
                timestampToUse = new Date(resultData.timestamp.seconds * 1000 + resultData.timestamp.nanoseconds / 1000000);
            } else if (typeof resultData.timestamp === 'string' || typeof resultData.timestamp === 'number') {
                // Handle as ISO string or Unix timestamp
                timestampToUse = new Date(resultData.timestamp);
            } else {
                throw new Error('Invalid timestamp');
            }

            // Validate the date
            if (isNaN(timestampToUse.getTime())) {
                throw new Error('Invalid timestamp');
            }

            await updateTournamentResult(user.uid, resultId, {
                ...resultData,
                timestamp: timestampToUse.toISOString()  // Convert to ISO string for Firebase storage
            });

            navigate('/results');
        } catch (error) {
            console.error("Error updating result:", error);
        }
    };

    // Define options with English values and localized labels
    const styleOptions = [
        { label: t('classic'), value: 'classic' },
        { label: t('skate'), value: 'skate' }
    ];

    const snowTypeOptions = [
        { label: t('fresh'), value: 'fresh' },
        { label: t('fine_grained'), value: 'fine_grained' },
        { label: t('coarse_grained'), value: 'coarse_grained' },
        { label: t('transformed'), value: 'transformed' },
        { label: t('wet'), value: 'wet' },
        { label: t('sleet'), value: 'sleet' },
        { label: t('artificial'), value: 'artificial' }
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>mySkiPark: Edit Result</title>
            </Helmet>
            <div className='py-4 px-2'>
                <form onSubmit={handleSubmit} >

                    {/* Inputs for each ski's ranking/score */}
                    {resultData.rankings.map((ranking, index) => (
                        <div key={index} className='flex flex-col mb-2'>
                            <label className='font-semibold'>{`${ranking.serialNumber} - ${ranking.grind}`}</label>
                            <SaveTestInput
                                type="number"
                                name={`score-${index}`}
                                onChange={handleInputChange}
                                value={ranking.score}
                            />
                        </div>
                    ))}
                    <h3 className='mt-5 mb-2 text-2xl font-semibold text-dominant '>Test data</h3>

                    <div className='space-y-2'>
                        <SaveTestInput
                            type="text"
                            name="location"
                            placeholder={t('location')}
                            onChange={handleInputChange}
                            value={resultData.location}
                            required={true}
                        />
                        <SaveTestInput
                            type="select"
                            name="style"
                            placeholder={t('style')}
                            onChange={handleInputChange}
                            value={resultData.style}
                            required
                            options={styleOptions}
                        />
                        <SaveTestInput
                            type="number"
                            name="temperature"
                            placeholder={t('temperature')}
                            onChange={handleInputChange}
                            value={resultData.temperature}
                        />
                        <SaveTestInput
                            type="select"
                            name="snowType"
                            placeholder={t('snow_type')}
                            value={resultData.snowType}
                            onChange={handleInputChange}
                            options={snowTypeOptions}
                            required
                        />
                        <SaveTestInput
                            type="number"
                            name="snowTemperature"
                            placeholder={t('snow_temperature')}
                            onChange={handleInputChange}
                            value={resultData.snowTemperature}
                        />
                        <SaveTestInput
                            type="number"
                            name="humidity"
                            placeholder={t('humidity')}
                            onChange={handleInputChange}
                            value={resultData.humidity}
                        />
                        <SaveTestInput
                            type="text"
                            name="comment"
                            placeholder={t('comment')}
                            onChange={handleInputChange}
                            value={resultData.comment}
                        />
                        <SaveTestInput
                            type="datetime-local"
                            name="date"
                            placeholder={t('date')}
                            onChange={handleInputChange}
                            value={formatDateForInputWithTime(resultData.timestamp)}
                            required={true}
                        />
                    </div>
                    <div className='flex space-x-4 my-5'>
                        <button
                            type="submit"
                            className='bg-btn px-5 py-3 rounded-3xl w-fit font-semibold text-btntxt hover:opacity-90'
                        >
                            {t('save')}
                        </button>
                        <BackBtn />
                    </div>
                </form>
            </div>
        </HelmetProvider>
    );
};

export default EditResultPage;
