import React, { createContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase/config'; 
import i18n from '../i18n';

export const UserPreferencesContext = createContext();

export const UserPreferencesProvider = ({ children }) => {
  const { user } = useAuth();
  const [english, setEnglish] = useState(true);
  const [colormode, setColormode] = useState('light'); // Default to light mode

  // Check for stored theme preference on load
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setColormode(storedTheme);
      document.documentElement.classList.toggle('dark', storedTheme === 'dark');
    } else {
      // If no user is logged in, apply light mode by default
      document.documentElement.classList.remove('dark');
      setColormode('light');
    }
  }, []);

  // Fetch preferences from Firestore if user is logged in
  useEffect(() => {
    if (user) {
      const userPreferencesRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(userPreferencesRef, (doc) => {
        const data = doc.data();
        if (data && data.preferences) {
          const { languagePreference, themePreference } = data.preferences;
          setEnglish(languagePreference === 'en');
          i18n.changeLanguage(languagePreference);

          // Apply the theme preference from Firestore
          const newTheme = themePreference || 'light';
          setColormode(newTheme);
          document.documentElement.classList.toggle('dark', newTheme === 'dark');

          // Update localStorage if it doesn't match Firestore preference
          const storedTheme = localStorage.getItem('theme');
          if (storedTheme !== newTheme) {
            localStorage.setItem('theme', newTheme); // Update localStorage
          }
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const toggleEnglish = () => {
    const newLanguage = english ? 'no' : 'en';
    setEnglish(!english);
    i18n.changeLanguage(newLanguage);
    const setLanguagePreference = httpsCallable(functions, 'setLanguagePreference');
    setLanguagePreference({ language: newLanguage }).catch(error => {
      console.error("Error setting language preference: ", error);
    });
  };

  const toggleColormode = () => {
    const newTheme = colormode === 'light' ? 'dark' : 'light';
    setColormode(newTheme);
    
    if (newTheme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    localStorage.setItem('theme', newTheme); // Store preference locally

    if (user) {
      const setThemePreference = httpsCallable(functions, 'setThemePreference');
      setThemePreference({ theme: newTheme }).catch(error => {
        console.error("Error setting theme preference: ", error);
      });
    }
  };

  return (
    <UserPreferencesContext.Provider value={{ english, setEnglish: toggleEnglish, colormode, setColormode: toggleColormode }}>
      {children}
    </UserPreferencesContext.Provider>
  );
};
