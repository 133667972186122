// SaveTestInput.js
import React from 'react';

const SaveTestInput = ({ name, type, placeholder, onChange, required, value, options }) => {
    return (
        <div className='mb-4'>
            <label className='text-text block mb-1'>
                {placeholder} {required && <span className="text-xl absolute text-red-500">*</span>}
            </label>
            {type === 'select' ? (
                <select
                    className='w-full text-inputtxt p-2 rounded-xl border'
                    name={name}
                    onChange={onChange}
                    value={value}
                    required={required}
                >
                    <option value="">{placeholder}</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    className='w-full text-inputtxt p-2 rounded-xl border'
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    required={required}
                />
            )}
        </div>
    );
};

export default SaveTestInput;
