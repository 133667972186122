import React, { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useProfileActions } from '../../hooks/useProfileActions';
import { useTranslation } from 'react-i18next';
import { UserPreferencesContext } from '../../context/UserPreferencesContext';
import BackBtn from '../../components/common/BackBtn';
import { MdEdit } from "react-icons/md";
import Spinner from '../../components/common/Spinner';
import Flag from 'react-flagkit';
import { MdDarkMode, MdLightMode } from "react-icons/md";


const SettingsPage = () => {
    const { user } = useAuth();
    const { resetPassword, deleteAccount, updateDisplayName } = useProfileActions(user);
    const { t } = useTranslation();
    const { english, setEnglish, colormode, setColormode } = useContext(UserPreferencesContext);
    const [newDisplayName, setNewDisplayName] = useState(user.displayName || '');
    const [status, setStatus] = useState('');
    const [isEditingUsername, setIsEditingUsername] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDisplayNameUpdate = async () => {
        setIsLoading(true);
        setStatus('');
        try {
            await updateDisplayName(newDisplayName);
            setStatus(t('success'));
            setIsEditingUsername(false);
        } catch (error) {
            setStatus(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteAccount = async () => {
        if (window.confirm(t('confirm_delete_account'))) {
            setIsLoading(true);
            try {
                await deleteAccount();
            } catch (error) {
                setStatus(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (isLoading) return <Spinner />;

    return (
        <div className="flex flex-col justofy-center p-4 mt-10 md:mt-24 mx-auto space-y-8 animate-fade-down animate-duration-300">

            {/* Username Section */}
            <div className="flex flex-col text-center ">
                <h4 className="font-semibold text-lg mb-2">{t('username')}</h4> 
                <div className="flex items-center justify-center space-x-2">
                    {isEditingUsername ? (
                        <div className='flex space-x-2'>
                            <input
                                type="text"
                                value={newDisplayName}
                                onChange={(e) => setNewDisplayName(e.target.value)}
                                className="w-full text-inputtxt p-2 rounded-xl border"
                            />
                            <button
                                className="bg-btn text-btntxt px-5 py-3 font-semibold rounded-3xl"
                                onClick={handleDisplayNameUpdate}
                            >
                                {t('save')}
                            </button>
                        </div>
                    ) : (
                        <div className="flex items-center justify-between space-x-4 bg-sbtn w-60 transition-all duration-300 hover:w-72 rounded-3xl px-5 py-3 font-semibold cursor-pointer" onClick={() => setIsEditingUsername(true)}>
                            <div className='text-text'>{user.displayName || t('no_username')}</div>
                            <div className=''><MdEdit size={20} /></div>
                        </div>
                    )}
                </div>
                {status && <div className="mt-2 text-dominant text-center">{status}</div>}
            </div>

            {/* Preferences Section */}
            <div className='flex justify-center'>
                <div className='mx-10 flex flex-col items-center space-y-4'>
                    <h3 className="font-semibold text-lg">{t('language')}</h3>

                    <label className="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" id="toggle" className="sr-only peer" checked={english} onChange={setEnglish} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-gray-300 transition duration-300 ease-in-out peer-checked:bg-btn after:absolute after:top-0.5 after:left-[2px] after:bg-btntxt after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-5"></div>
                    </label>
                    <div className="flex items-center justify-center">{english ? <Flag country="US" size={20} /> : <Flag country="NO" size={20} />}</div>
                </div>

                {/* Color Mode Toggle */}
                <div className='mx-10 flex flex-col items-center space-y-4'>
                    <h3 className="font-semibold text-lg">{t('theme')}</h3>

                    <label className="inline-flex relative items-center cursor-pointer">
                        <input
                            type="checkbox"
                            id="toggle"
                            className="sr-only peer"
                            checked={colormode === 'dark'} // Fix boolean condition here
                            onChange={() => setColormode(colormode === 'light' ? 'dark' : 'light')} // Correct toggle logic
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-gray-300 transition duration-300 ease-in-out peer-checked:bg-btn after:absolute after:top-0.5 after:left-[2px] after:bg-btntxt after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-5"></div>
                    </label>
                    <div className="flex items-center justify-center">{colormode === 'dark' ? <MdDarkMode size={20} /> : <MdLightMode size={20} />}</div>

                </div>

            </div>

            {/* Actions Section */}
            <div className="flex flex-col justify-center gap-4 md:mx-24">
                <button
                    className="flex-1 bg-btn w-1/2 mx-auto text-btntxt py-3 px-5 rounded-3xl hover:opacity-90"
                    onClick={resetPassword}
                >
                    {t('reset_password')}
                </button>
                <button
                    className="flex-1 bg-delete w-1/2 mx-auto text-white py-3 px-5 rounded-3xl hover:font-semibold"
                    onClick={handleDeleteAccount}
                >
                    {t('delete_account')}
                </button>
            </div>

            <div className="flex justify-center items-center mt-20">
                <BackBtn />
            </div>
        </div>
    );
};

export default SettingsPage;
