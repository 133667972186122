import React, { useContext } from 'react';
import logo from '../../../assets/logo2.png';
import logoWhite from '../../../assets/logo_white.png';
import Weather from '../../Weather';
import { UserPreferencesContext } from '../../../context/UserPreferencesContext';
import { useLocation, useParams } from 'react-router-dom'; // Import useParams
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { colormode } = useContext(UserPreferencesContext);
  const location = useLocation();
  const { t } = useTranslation();
  const { state } = location;
  
  const { id } = useParams(); // useParams for dynamic segments

  const pageNames = {
    '/': '',
    '/skis': t('skipark'),
    '/add-skis': state?.isEdit ? t('edit_ski_pair') : t('add_ski_pair'),
    '/testing': t('testing'),
    '/test-result': t('test_result'),
    '/results': t('results'),
    '/account': t('account'),
    '/settings': t('settings'),
    '/contact': t('contact'),
  };

  // Handle dynamic URLs like '/edit-result/:id'
  let pageName = pageNames[location.pathname] || '';

  if (location.pathname.startsWith('/edit-result/')) {
    pageName = t('edit_test'); // Set page name for editing a result
  }

  return (
    <header className='flex flex-col md:grid grid-cols-3 items-center md:mx-4 space-y-2 p-4 relative'>
      <div className='flex-1 flex'>
        <img src={colormode === 'light' ? logo : logoWhite} alt="Logo" className='h-7' />
      </div>
      <div className="flex-1 text-center hidden md:block text-3xl font-semibold">
        {pageName && <span>{pageName}</span>}
      </div>

      <Weather />
    </header>
  );
}

export default Header;
