// CombinedConditionsHeatmap.jsx

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const CombinedConditionsHeatmap = ({
    temperatureList,
    allSnowTypes,
    combinedPerformanceData,
}) => {
    const { t } = useTranslation();

    // Define color scale based on categories
    const categoryColors = {
        'great': '#4DB6FF',      // Blue
        'good': '#7DCEA0',       // Light Green
        'average': '#F1C40F',    // Yellow
        'bad': '#E67E22',        // Orange
        'very_bad': '#E74C3C',   // Red
        'unknown': '#BDC3C7',     // Gray for Unknown
    };

    // Organize data for the heatmap
    const heatmapData = useMemo(() => {
        return allSnowTypes.map(snowType => {
            const row = { snowType };
            temperatureList.forEach(temp => {
                const performance = combinedPerformanceData.find(
                    item => item.temperature === temp && item.snowType === snowType
                );
                row[temp] = performance ? performance : { category: 'Unknown', count: 0, averageRank: null };
            });
            return row;
        });
    }, [allSnowTypes, temperatureList, combinedPerformanceData]);

    return (
        <div className="overflow-x-auto overflow-y-hidden">
            <table className="min-w-full table-auto border-collapse">
                <thead>
                    <tr>
                        <th className="p-2 bg-background shadow-sm text-left text-sm font-medium">
                            {t('snow_type')}
                        </th>
                        {temperatureList.map((temp, index) => (
                            <th key={index} className="p-2 bg-background shadow-sm text-center text-sm font-medium">
                                {temp}°C
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {heatmapData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td className="px-2 py-1 text-sm font-semibold">
                                {/* Capitalize snowType for display */}
                                {t(row.snowType)}
                            </td>
                            {temperatureList.map((temp, colIndex) => {
                                const performance = row[temp];
                                const category = performance.category || 'Unknown';
                                const bgColor = categoryColors[category] || '#FFFFFF';

                                // Prepare tooltip content
                                const tooltipContent = performance.category !== 'Unknown' ? `
                                ${t('category')}: ${t(performance.category)}
                                ${t('number_of_tests')}: ${performance.count}
                                ${t('average_rank')}: ${performance.averageRank}
                            ` : `
                                ${t('category')}: ${t('unknown')}
                                ${t('number_of_tests')}: 0
                            `;


                                return (
                                    <td key={colIndex} className="px-2 py-1 text-center">
                                        {category !== 'Unknown' ? (
                                            <div
                                                className="w-5 h-5 mx-auto rounded-full"
                                                style={{ backgroundColor: bgColor }}
                                                title={tooltipContent}
                                            ></div>
                                        ) : (
                                            <span className="text-sm">--</span>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Legend */}
            <div className="grid grid-cols-3 mt-4">
                {Object.entries(categoryColors).map(([category, color]) => (
                    <div key={category} className="flex items-center space-x-1">
                        {category === 'unknown' ?
                            <span className='w-4 h-4 flex items-center justify-center'>--</span>
                            :
                            <span
                                className="w-4 h-4 rounded-full"
                                style={{ backgroundColor: color }}
                            ></span>}
                        <span className="text-sm">{t(category.toLowerCase().replace(' ', '_'))}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CombinedConditionsHeatmap;
