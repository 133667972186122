// src/components/ResultDetails.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const ResultDetails = ({ result, showDetails }) => {
    const { t } = useTranslation();

    return showDetails ? (
        <div className='mb-4'>
            <h3 className='mb-2 font-semibold text-dominant '>{t('details')}</h3>
            <ul className='text-sm grid grid-cols-2 gap-2'>
                <li>{t('snow_type')}: {t(result.snowType)}</li>
                <li>{t('snow_temperature')}: {result.snowTemperature}</li>
                <li>{t('humidity')}: {result.humidity}</li>
                <li>{t('comment')}: {result.comment}</li>
            </ul>
        </div>
    ) : null;
};

export default ResultDetails;
